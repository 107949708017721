import type { GetStaticPathsContext, GetStaticPropsContext } from "next";
import Head from "next/head";
import { BuilderContent } from "@builder.io/sdk";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import {
  getBuilderStaticPaths,
  getBuilderStaticProps,
  renderLink,
} from "@services/builderIO";
import "@src/builder-registry";
import builderConfig from "@config/builder";
import NotFound from "@components/errors/not-found.component.tsx";
import SEO from "@lib/seo";
import Script from "next/script";

builder.init(builderConfig.apiKey);

export async function getStaticProps(
  context: GetStaticPropsContext<{ path: string[] }>
) {
  return await getBuilderStaticProps("page", context);
}

export async function getStaticPaths(context: GetStaticPathsContext) {
  return await getBuilderStaticPaths("page");
}

export default function Page({
  builderPage,
}: {
  builderPage: BuilderContent | null;
}) {
  const isPreviewing = useIsPreviewing();
  const { title, description, image } = builderPage?.data! || {};
  return (
    <>
      {builderPage || isPreviewing ? (
        <>
          {title && (
            <SEO title={title} description={description} image={image} />
          )}
          <BuilderComponent
            options={{ enrich: true }}
            renderLink={renderLink}
            model="page"
            content={builderPage || undefined}
          />
        </>
      ) : (
        <>
          <Head>
            <meta name="robots" content="noindex" />
          </Head>
          <NotFound type={"404"} />
        </>
      )}
    </>
  );
}
